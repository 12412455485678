<template>
  <div>
    <van-dropdown-menu>
      <van-dropdown-item
        v-model="value"
        :options="options"
        @change="onChange"
      />
    </van-dropdown-menu>
    <van-grid v-if="detail" :column-num="3">
      <van-grid-item>
        <div>{{ $t('maxPicking') }}</div>
        <div>{{ detail.maxPicking || 0 }}</div>
      </van-grid-item>
      <van-grid-item>
        <div>{{ $t('minPicking') }}</div>
        <div>{{ detail.minPicking || 0 }}</div>
      </van-grid-item>
      <van-grid-item>
        <div>{{ $t('avgPicking') }}</div>
        <div>{{ detail.avgPicking || 0 }}</div>
      </van-grid-item>
    </van-grid>
    <ve-line :data="data" :legend-visible="false" />
  </div>
</template>
<script>
import dayjs from 'dayjs'

export default {
  data() {
    return {
      data: {
        columns: ['date', 'number'],
        rows: [],
      },
      value: '',
      detail: null,
      options: [],
    }
  },
  created() {
    // const { productList, index } = this.$route.params
    const productList = JSON.parse(sessionStorage.getItem('productList'))
    const index = sessionStorage.getItem('index')
    this.options = productList.map((v) => ({
      value: v.productCode,
      text: v.productName,
      ...v,
    }))
    this.value = productList[index].productCode
    this.listPickingTrend(index)
  },
  methods: {
    async listPickingTrend(index) {
      const { vmCode, productCode } = this.options[index]
      const { data } = await this.$api.listPickingTrend({ vmCode, productCode })
      console.log('listPickingTrend===>', data)
      this.detail = data
      const rows = []
      for (const key in data.pickingTrend) {
        rows.unshift({
          date: dayjs(key).format('MM/DD'),
          number: data.pickingTrend[key],
        })
      }
      this.data.rows = rows
    },
    onChange(val) {
      // console.log(val)
      this.listPickingTrend(
        this.options.findIndex((v) => v.productCode === val)
      )
    },
  },
}
</script>